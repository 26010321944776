<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> သတင်း ထည့်ရန်</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputTitle" class="col-sm-2 col-form-label">သတင်း ခေါင်းစီး <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="postRequest.title" @blur="validationRule()"
										class="form-control" id="inputTitle">
									<small class="text-danger">{{validationData.title}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPostedDate" class="col-sm-2 col-form-label">ရက်စွဲ<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="date" v-model="postRequest.posted_date" @blur="validationRule()"
										class="form-control" id="inputPostedDate">
									<small class="text-danger">{{validationData.posted_date}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">ဓာတ်ပုံ  <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="postRequest.image" v-bind:src="postRequest.image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'postCreate',
		metaInfo: {
            title: "Post Create",
            titleTemplate: "%s ← SC Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage: "",
				postRequest: {
					title: "",
					posted_date: "",
					image: ""
				},
				validationData: {
					title: "",
					posted_date: "",
					image: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				postStoreAction: 'postStoreAction',
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.postRequest.image = e.target.result
					this.validationRule()
				};
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.postRequest
				await this.postStoreAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'post-list', query: {page: 1, reverse: 'desc', sort: 'created_at', title: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.postRequest.title == "") {
					this.validationData.title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.title = ""
					isValidation.push(false)
				}
				if (this.postRequest.posted_date == "") {
					this.validationData.posted_date = "The posted_date field is required."
					isValidation.push(true)
				} else {
					this.validationData.posted_date = ""
					isValidation.push(false)
				}
				if (this.postRequest.image == "") {
					this.validationData.image = "The image field is required."
					isValidation.push(true)
				} else {
					console.log(this.postRequest.image)
					this.validationData.image = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>